.field-label{
    display: block;
    padding-bottom: 4px;
    font-weight: 500;

    &.required::after {
        display: inline-block;
        margin-left: 2px;
        color: #ff4d4f;
        font-size: 14px;
        font-family: SimSun, sans-serif;
        line-height: 1;
        content: '*';
    }
}

.table-actions {
    span {
        display: flex;
    }
}

.form_body {

    .ant-form-item-has-error {
        input{
            border-color: #ff4d4f !important;
        }
    }
}

.simple-form-container{
    border-radius: 10px;
    padding: 30px;
    // max-width: 1100px;
    .avatar-uploader {
        .ant-upload {
            width: 100%;
            height: 142px;
            overflow: hidden;
        }

        .ant-upload-list-picture-card-container{
            position: absolute;
            top: 0;
            left: 0;

            margin: 0;
            width: 100%;
            height: 130px;

            .ant-upload-list-item-thumbnail img {
                object-fit: cover;
            }
            .ant-upload-list-item-actions {
                display: flex;
                align-items: center;

                >a {
                    margin-top: -4px;
                }
            }
        }
        
        &.company-detail {
          .ant-upload-select-picture-card {
            height: 202px;
          }
        }
    }


    @media @max768 {
        padding: 20px;

        .ant-col {
            min-width: 142px;
        }
    }
}

.ant-form-item-explain-error {
    font-size: 10px;
}

.text-label {
    font-size: 14px;
    color: @dark-gray;
    padding: 0;
}

.input-field {
    border-radius: 6px;
    margin-bottom: 15px;
    box-shadow: none;
}

.form-item-field {
    border-radius: 6px;
    box-shadow: none;
    border: 1px solid @cyan;
}

.ant-input-group-compact {
    display: flex !important;
    justify-content: flex-end;
}

.group-input {
    border-radius: 6px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border: 1px solid @cyan;
    border-left: none;
    box-shadow: none;
    width: 70%;
    &:hover {
        border: 1px solid @cyan !important;
        border-left: none !important;
        box-shadow: none !important;
    }
}

.group-datepicker {
    border-radius: 6px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-left: none;
    box-shadow: none;
    border-color: @cyan;
    width: 70%;
    &:hover {
        border-color: @cyan;
        box-shadow: none;
    }
}

.group-select {
    border-color: @cyan;
    width: 70%;
    box-shadow: none;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-left: none;
    border-radius: 6px;
    .ant-select-selector {
        border: 1px solid @cyan !important;
        box-shadow: none !important;
        border-left: none !important;
    }
}

.group-filter-btn {
    border-radius: 6px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-right: none;
    border-color: @cyan;
    color: @dark-gray;
    font-size: 14px;
    margin: 0;
    width: auto;
    text-align: start;
    &:hover {
        color: @dark-gray;
        border-color: @cyan;
        box-shadow: none;
    }
    &:focus {
        color: @dark-gray;
        border-color: @cyan;
        box-shadow: none;
    }
    &::after {
        box-shadow: none !important;
    }
}

.default-input {
    box-shadow: none;
    border: 1px solid @cyan;
    &:hover {
        border: 1px solid @cyan;
        box-shadow: none;
    }
    &:focus {
        border: 1px solid @cyan;
        box-shadow: none;
    }
}

.default-select {
    border-radius: 6px;
    box-shadow: none;
    .ant-select-selector {
        border: 1px solid @cyan !important;
        box-shadow: none !important;
    }
}

.default-picker {
    border-radius: 6px;
    box-shadow: none;
    border-color: @cyan;
    border: 1px solid @cyan !important;
    &:hover {
        border: 1px solid @cyan;
        border-color: @cyan;
        box-shadow: none;
    }
}

.input-with-addon-before {
    box-shadow: none;
    .ant-input-group-addon {
        color: #000000;
        font-size: 16px;
        background-color: white;
        border-right: none !important;
        padding-right: 0;
        border: 1px solid @cyan;
    }
    .ant-input {
        border-left: none !important;
        border: 1px solid @cyan;
        &:focus {
            box-shadow: none;
        }
    }
    .ant-input-status-error {
        border: 1px solid @cyan !important;
        border-left: none !important;
        &:focus {
            box-shadow: none !important;
        }
    }
    &:hover {
        box-shadow: none;
    }
    &:focus {
        box-shadow: none;
    }
}

.label-input {
    padding: 0;
    height: auto !important;
    font-weight: 600;
}

.default-input-label {
    box-shadow: none;
    border: none;
    font-weight: 600;
    padding: 0;
    height: auto !important;
    &:hover {
        border: none;
        box-shadow: none;
    }
    &:focus {
        border: none;
        box-shadow: none;
    }
}

.default-textarea-label {
    box-shadow: none;
    border: none;
    font-weight: 600;
    padding: 0;
    &:hover {
        border: none;
        box-shadow: none;
    }
    &:focus {
        border: none;
        box-shadow: none;
    }
}