@import '../../../views-dir.less';

.sidebar {
  background: #172C44 !important;
  .ant-menu-item-selected {
    background-color: @cyan !important;
    border-radius: inherit !important;
  }
}

.dashboard-menu {
  height: 92vh !important;
  width: 100% !important;
}

.dashboard-logout {
  height: 8vh !important;
  width: 100% !important;
  padding-left: 6px !important;
}

.dashboard-logout-btn {
  color: rgba(255, 255, 255, .75) !important;
  display: flex !important;
  align-items: center !important;
  font-weight: 500 !important;
}

.menu-item-class {
  font-size: 24px !important;
}

.ant-layout-sider-children {
  background-color: #172C44 !important;
}
@primary-color: #00AD4C;@border-radius-base: 5px;@border-color-base: #49CB5C;