@import './variables.less';

.dark-blue-btn {
    background: @dark-blue;
    border: none;
    color: white;
    border-radius: 6px;
    &:hover {
        color: white;
        background: rgba(#172C44, .75);
        border-color: rgba(#172C44, .75);
    }
    &:focus {
        color: white;
        background: @dark-blue;
        border-color: @dark-blue;
    }
}

.dark-magenta-btn {
    background: @dark-magenta;
    border-color: @dark-magenta;
    color: white;
    border-radius: 6px;
    display: flex;
    align-items: center;
    height: auto;
    &:hover {
        color: white;
        background: rgba(#462144, .75);
        border-color: rgba(#462144, .75);
    }
    &:focus {
        color: white;
        background: @dark-magenta;
        border-color: @dark-magenta;
    }
}

.cyan-btn {
    background: @cyan;
    border-color: @cyan;
    color: white;
    border-radius: 6px;
    &:hover {
        color: white;
        background: rgba(#3DB5C5, .75);
        border-color: rgba(#3DB5C5, .75);
    }
    &:focus {
        color: white;
        background: @cyan;
        border-color: @cyan;
    }
}

.soft-red-btn {
    background: @soft-red;
    border-color: @soft-red;
    color: white;
    border-radius: 6px;
    &:hover {
        color: white;
        background: rgba(#F36750, .75);
        border-color: rgba(#F36750, .75);
    }
    &:focus {
        color: white;
        background: @soft-red;
        border-color: @soft-red;
    }
}

.close-btn {
    background: lightgray;
    border-color: lightgray;
    color: black;
    border-radius: 6px;
    &:hover {
        color: black;
        background: rgba(#D3D3D3, .75);
        border-color: rgba(#D3D3D3, .75);
    }
    &:focus {
        color: black;
        background: lightgray;
        border-color: lightgray;
    }
}

.light-gray-btn {
    background: @light-gray;
    border-color: @light-gray;
    color: @dark-blue;
    border-radius: 6px;
    &:hover {
        color: @dark-blue;
        background: rgba(#F2F2F2, .75);
        border-color: rgba(#F2F2F2, .75);
    }
    &:focus {
        color: @dark-blue;
        background: @light-gray;
        border-color: @light-gray;
    }
}

.action-btn {
    color:gray;
    font-size: 18px !important;
    .anticon {
        display: flex;
        justify-content: flex-start;
    }
}