body {
  margin: 0;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1,
h2 {
  font-weight: 600;
}
.main-layout {
  background: #F3F3F7;
}
.d-header {
  position: sticky;
  top: 0;
  z-index: 3;
  background: #F3F3F7;
  padding-bottom: 0.5rem;
}
@media (max-width: 426px) {
  .main-layout > main.ant-layout-content {
    padding: 0 20px 20px !important;
  }
}
@font-face {
  font-family: 'Montserrat';
  src: url('/src/assets/fonts/Montserrat/Montserrat-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Gotham';
  src: url('/src/assets/fonts/Gotham/Gotham-Medium.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins';
  src: url('/src/assets/fonts/Poppins/Poppins-Regular.ttf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
.field-label {
  display: block;
  padding-bottom: 4px;
  font-weight: 500;
}
.field-label.required::after {
  display: inline-block;
  margin-left: 2px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}
.table-actions span {
  display: flex;
}
.form_body .ant-form-item-has-error input {
  border-color: #ff4d4f !important;
}
.simple-form-container {
  border-radius: 10px;
  padding: 30px;
}
.simple-form-container .avatar-uploader .ant-upload {
  width: 100%;
  height: 142px;
  overflow: hidden;
}
.simple-form-container .avatar-uploader .ant-upload-list-picture-card-container {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  width: 100%;
  height: 130px;
}
.simple-form-container .avatar-uploader .ant-upload-list-picture-card-container .ant-upload-list-item-thumbnail img {
  object-fit: cover;
}
.simple-form-container .avatar-uploader .ant-upload-list-picture-card-container .ant-upload-list-item-actions {
  display: flex;
  align-items: center;
}
.simple-form-container .avatar-uploader .ant-upload-list-picture-card-container .ant-upload-list-item-actions > a {
  margin-top: -4px;
}
.simple-form-container .avatar-uploader.company-detail .ant-upload-select-picture-card {
  height: 202px;
}
@media (max-width: 768px) {
  .simple-form-container {
    padding: 20px;
  }
  .simple-form-container .ant-col {
    min-width: 142px;
  }
}
.ant-form-item-explain-error {
  font-size: 10px;
}
.text-label {
  font-size: 14px;
  color: #696969;
  padding: 0;
}
.input-field {
  border-radius: 6px;
  margin-bottom: 15px;
  box-shadow: none;
}
.form-item-field {
  border-radius: 6px;
  box-shadow: none;
  border: 1px solid #3DB5C5;
}
.ant-input-group-compact {
  display: flex !important;
  justify-content: flex-end;
}
.group-input {
  border-radius: 6px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border: 1px solid #3DB5C5;
  border-left: none;
  box-shadow: none;
  width: 70%;
}
.group-input:hover {
  border: 1px solid #3DB5C5 !important;
  border-left: none !important;
  box-shadow: none !important;
}
.group-datepicker {
  border-radius: 6px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-left: none;
  box-shadow: none;
  border-color: #3DB5C5;
  width: 70%;
}
.group-datepicker:hover {
  border-color: #3DB5C5;
  box-shadow: none;
}
.group-select {
  border-color: #3DB5C5;
  width: 70%;
  box-shadow: none;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-left: none;
  border-radius: 6px;
}
.group-select .ant-select-selector {
  border: 1px solid #3DB5C5 !important;
  box-shadow: none !important;
  border-left: none !important;
}
.group-filter-btn {
  border-radius: 6px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-right: none;
  border-color: #3DB5C5;
  color: #696969;
  font-size: 14px;
  margin: 0;
  width: auto;
  text-align: start;
}
.group-filter-btn:hover {
  color: #696969;
  border-color: #3DB5C5;
  box-shadow: none;
}
.group-filter-btn:focus {
  color: #696969;
  border-color: #3DB5C5;
  box-shadow: none;
}
.group-filter-btn::after {
  box-shadow: none !important;
}
.default-input {
  box-shadow: none;
  border: 1px solid #3DB5C5;
}
.default-input:hover {
  border: 1px solid #3DB5C5;
  box-shadow: none;
}
.default-input:focus {
  border: 1px solid #3DB5C5;
  box-shadow: none;
}
.default-select {
  border-radius: 6px;
  box-shadow: none;
}
.default-select .ant-select-selector {
  border: 1px solid #3DB5C5 !important;
  box-shadow: none !important;
}
.default-picker {
  border-radius: 6px;
  box-shadow: none;
  border-color: #3DB5C5;
  border: 1px solid #3DB5C5 !important;
}
.default-picker:hover {
  border: 1px solid #3DB5C5;
  border-color: #3DB5C5;
  box-shadow: none;
}
.input-with-addon-before {
  box-shadow: none;
}
.input-with-addon-before .ant-input-group-addon {
  color: #000000;
  font-size: 16px;
  background-color: white;
  border-right: none !important;
  padding-right: 0;
  border: 1px solid #3DB5C5;
}
.input-with-addon-before .ant-input {
  border-left: none !important;
  border: 1px solid #3DB5C5;
}
.input-with-addon-before .ant-input:focus {
  box-shadow: none;
}
.input-with-addon-before .ant-input-status-error {
  border: 1px solid #3DB5C5 !important;
  border-left: none !important;
}
.input-with-addon-before .ant-input-status-error:focus {
  box-shadow: none !important;
}
.input-with-addon-before:hover {
  box-shadow: none;
}
.input-with-addon-before:focus {
  box-shadow: none;
}
.label-input {
  padding: 0;
  height: auto !important;
  font-weight: 600;
}
.default-input-label {
  box-shadow: none;
  border: none;
  font-weight: 600;
  padding: 0;
  height: auto !important;
}
.default-input-label:hover {
  border: none;
  box-shadow: none;
}
.default-input-label:focus {
  border: none;
  box-shadow: none;
}
.default-textarea-label {
  box-shadow: none;
  border: none;
  font-weight: 600;
  padding: 0;
}
.default-textarea-label:hover {
  border: none;
  box-shadow: none;
}
.default-textarea-label:focus {
  border: none;
  box-shadow: none;
}
.max-width {
  width: 100%;
}
.width-300 {
  width: 300px;
}
.mobile-header {
  max-width: 270px;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.b-radius-20 {
  border-radius: 20px;
}
.b-radius-10 {
  border-radius: 10px;
}
.profile-img {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: #e7e7e7;
  color: #ffffff;
  font-size: 24px;
  text-align: center;
}
.d-block {
  display: block;
}
.visible-xs {
  display: none !important;
}
.hide-xs {
  display: block !important;
  margin-left: 20px;
}
.d-i-block {
  display: inline-block;
}
.max-height {
  height: 100%;
}
.icon-left .ant-picker-suffix {
  position: absolute;
  margin: 0;
  left: 0;
  color: rgba(0, 0, 0, 0.85);
}
.icon-left input {
  padding-left: 18px !important;
}
.ant-form-item-has-error .icon-left .ant-picker-suffix {
  color: #ff4d4f;
}
.custom-tooltip {
  background: white;
  color: inherit;
  font-size: inherit;
  border-radius: 2px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 1px 2px;
  padding: 5px 9px;
}
.icon-container {
  border-radius: 5px;
  padding: 8px;
  background-color: white;
}
.dynamic-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.dynamic-info h2 {
  margin: 0;
  font-weight: 600;
}
.dynamic-info p {
  text-align: center;
  margin: 20px 0;
}
.bg-primary {
  background-color: #00AD4C;
}
.bg-secondary {
  background-color: #014050;
}
.bg-peach {
  background-color: #F7CB73;
}
.bg-green {
  background-color: #52C41A;
}
.bg-white {
  background-color: #ffffff;
}
.box-shadow {
  box-shadow: 0px 2px 16px 1px rgba(0, 0, 0, 0.1);
}
.padding-s {
  padding: 10px;
}
.padding-s-v {
  padding: 10px 0;
}
.pad-bottom-s {
  padding-bottom: 10px;
}
.padding-m {
  padding: 20px;
}
.padding-m-v {
  padding: 20px 0;
}
.pad-bottom-s-s {
  padding-bottom: 8px;
}
.r-margin {
  margin: 0 !important;
}
.margin-top-s {
  margin-top: 10px;
}
.margin-bottom-s {
  margin-bottom: 10px;
}
.margin-top-m {
  margin-top: 20px;
}
.margin-bottom-m {
  margin-bottom: 20px;
}
.margin-m-v {
  margin: 20px 0;
}
.margin-right-s {
  margin-right: 10px;
}
.margin-auto-r {
  margin-right: auto;
}
.space-left-s-xs {
  margin-left: 10px;
}
.margin-auto-l {
  margin-left: auto;
}
.gap-flex {
  gap: 20px !important;
}
.gap-10 {
  gap: 10px !important;
}
.gap-20 {
  gap: 20px !important;
}
.font-11 {
  font-size: 11px;
}
.font-12 {
  font-size: 12px;
}
.font-13 {
  font-size: 13px;
}
.font-14 {
  font-size: 14px;
}
.font-16 {
  font-size: 16px;
}
.font-18 {
  font-size: 18px;
}
.font-20 {
  font-size: 20px;
}
.font-26 {
  font-size: 30px;
}
.font-30 {
  font-size: 30px;
}
.font-60 {
  font-size: 60px;
}
.fw-600 {
  font-weight: 600;
}
.fw-400 {
  font-weight: 450;
}
.text-primary {
  color: #00AD4C;
}
.text-secondary {
  color: #014050;
}
.text-tertiary {
  color: #4A7E9F;
}
.text-blue {
  color: #89D4F0;
}
.text-white {
  color: #ffffff;
}
.text-error {
  color: #EA3B3B;
}
.link-secondary,
.text-gray {
  color: #999999;
}
.link-primary:hover {
  color: #00AD4C;
}
.link-secondary:hover {
  color: #014050;
}
.text-underline {
  text-decoration: underline;
}
.text-uppercase {
  text-transform: uppercase;
}
.text-capitalize {
  text-transform: capitalize;
}
.text-italic {
  font-style: italic;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-justify {
  text-align: justify;
}
.c-pointer {
  cursor: pointer;
}
.c-disabled {
  cursor: not-allowed;
}
.tableClass th {
  color: #00AD4C;
}
.tableClassOne thead > tr > th {
  color: 'pink';
}
.tooltip-v-align > svg {
  position: relative;
  top: -3px;
  font-size: 16px;
}
#tooltip-inner--w-300 {
  width: 300px;
  white-space: pre-line;
}
#tooltip-inner--w-350 {
  width: 350px;
  white-space: pre-line;
}
#tooltip-inner--w-400 {
  width: 400px;
  white-space: pre-line;
}
#tooltip-inner--w-450 {
  width: 450px;
  white-space: pre-line;
}
#tooltip-inner--w-500 {
  width: 500px;
  white-space: pre-line;
}
@media (max-width: 768px) {
  .visible-xs {
    display: block !important;
  }
  .hide-xs {
    display: none !important;
  }
  .max-width-xs {
    width: 100%;
  }
  .hidden-xs {
    display: none;
  }
  .mobile-add {
    position: fixed;
    right: 14px;
    bottom: 14px;
    width: 50px !important;
    height: 50px !important;
    z-index: 1;
  }
  .space-top-m-xs {
    margin-top: 20px;
  }
  .space-left-s-xs {
    margin-left: 0;
  }
  .action-container {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
  .action-container * {
    width: 100%;
    margin: 0 !important;
    padding: 0;
  }
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  font-weight: 600;
}
.ant-input-affix-wrapper {
  padding: 8px 11px;
}
.ant-picker-active-bar {
  background-color: #3DB5C5 !important;
}
.ant-picker-cell-today > div::before {
  border: 1px solid #3DB5C5;
}
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner {
  background: #3DB5C5;
}
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background: #3DB5C5;
}
.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background: rgba(61, 181, 197, 0.25) !important;
}
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before {
  background: rgba(61, 181, 197, 0.25);
}
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
  background: rgba(61, 181, 197, 0.25) !important;
}
.ant-select-selection-selected-value {
  background: rgba(61, 181, 197, 0.25);
}
.ant-select-item-option-active {
  background: rgba(61, 181, 197, 0.25) !important;
  font-weight: 600;
}
.ant-select-item-option-active:hover {
  background: rgba(61, 181, 197, 0.1);
}
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: 1px solid #3DB5C5 !important;
}
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
  background: #3DB5C5 !important;
}
.ant-picker-today-btn {
  color: #3DB5C5;
}
.ant-picker-today-btn:hover {
  color: #3DB5C5;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: rgba(61, 181, 197, 0.5);
}
.ant-picker-ok > button {
  background-color: #3DB5C5;
  border-color: #3DB5C5;
}
.ant-picker-active-bar {
  background-color: #3DB5C5;
}
.ant-picker-month-btn:hover {
  color: #3DB5C5 !important;
}
.ant-picker-year-btn:hover {
  color: #3DB5C5 !important;
}
.ant-picker-decade-btn:hover {
  color: #3DB5C5;
}
a.ant-btn {
  line-height: 37px;
}
.ant-btn-default.default {
  background: #F3F3F7;
  color: #A8A8A8;
  border-color: rgba(168, 168, 168, 0.1);
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont;
}
.ant-btn-default.default:hover {
  background: #eaeaf1;
}
.ant-btn-leftIcon {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ant-btn-leftIcon > span {
  width: auto;
}
.ant-btn-leftIcon > span:not(.anticon) {
  margin-left: 8px;
  margin-right: 0;
}
.ant-btn-rightIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row-reverse;
}
.ant-btn-rightIcon > span {
  width: auto;
}
.ant-btn-rightIcon > span:not(.anticon) {
  margin-left: 0;
  margin-right: 8px;
}
.ant-btn-rightIcon > img {
  max-width: 18px;
}
.ant-btn-outlined-secondary {
  color: #014050;
  border-color: #014050;
}
.ant-btn-outlined-secondary[disabled] {
  border-color: #E7E9EF;
}
.ant-btn-outlined-secondary[disabled]:hover {
  border-color: #E7E9EF;
}
.ant-btn-outlined-secondary:hover {
  opacity: 0.8;
  color: #014050;
  border-color: #014050;
}
.ant-form label {
  font-size: 12px;
  color: #014050;
}
.ant-form-item-explain.ant-form-item-explain-error {
  padding: 10px 8px 20px 0px;
}
.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button {
  height: 40px;
  font-size: 18px;
}
.anticon svg {
  vertical-align: middle;
}
.ant-menu-vertical > .ant-menu-item,
.ant-menu-vertical-left > .ant-menu-item,
.ant-menu-vertical-right > .ant-menu-item,
.ant-menu-inline > .ant-menu-item,
.ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 48px;
  line-height: 48px;
}
.ant-form-item {
  margin-bottom: 18px;
}
.ant-form-item-with-help {
  margin-bottom: 0;
}
.ant-form-item-explain {
  font-size: 12px;
}
a {
  color: #014050;
}
.ant-form-item-control-input-content > input {
  height: 40px;
}
.ant-form-item-control-input-content .ant-select .ant-select-selector {
  height: 40px;
  align-items: center;
}
.ant-table-thead > tr > th {
  background: #fff;
  font-weight: 600;
}
.ant-tabs-tab-btn {
  font-weight: 600;
}
.ant-layout-sider-children {
  background-color: #ffffff;
}
.ant-popconfirm .ant-popover-buttons {
  min-width: 150px;
}
.ant-popconfirm .ant-popover-buttons > button {
  display: unset;
  min-width: 60px;
  height: 28px;
}
.ant-pagination-item-active {
  background: #fff;
  border-color: #3DB5C5;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont;
}
.ant-pagination-item-active a {
  color: #3DB5C5 !important;
}
.ant-pagination-item a {
  color: black;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont;
}
.ant-pagination-item a:hover {
  color: #3DB5C5;
}
.ant-pagination-item-link {
  display: flex !important;
  align-items: center;
  justify-content: center;
}
.ant-modal-confirm .ant-modal-confirm-btns {
  float: right;
}
::-moz-selection {
  background: #3DB5C5;
}
::selection {
  background: #3DB5C5;
}
.ant-btn > .anticon + span,
.ant-btn > span + .anticon {
  margin-left: 8px;
  display: inline-flex;
  align-self: center;
}
.ant-select-clear {
  font-size: 14px;
  display: flex;
  align-items: center;
}
.ant-input-clear-icon {
  font-size: 14px;
  display: flex;
  align-items: center;
}
.ant-btn-primary {
  color: #fff;
  border-color: #3DB5C5;
  background: #3DB5C5;
}
.ant-btn-primary:hover {
  color: #fff;
  border-color: rgba(61, 181, 197, 0.75);
  background: rgba(61, 181, 197, 0.75);
}
.ant-btn-primary:focus {
  color: #fff;
  border-color: #3DB5C5;
  background: #3DB5C5;
}
.ant-picker-ok .ant-btn {
  border: 1px solid #3DB5C5;
  background: #3DB5C5;
}
.ant-picker-ok .ant-btn:hover {
  border: 1px solid #3DB5C5;
  background: #3DB5C5;
}
.ant-form-item-explain-error {
  font-size: 12px;
}
.ant-empty-description {
  font-size: 0px;
}
.ant-empty-description::after {
  font-size: 14px;
  content: 'No Data Found';
}
.ant-message-custom-content {
  display: inline-flex;
  align-items: center;
}
.ant-table-thead > tr > th {
  color: #000000;
}
.ant-typography,
h3.ant-typography {
  color: #000000;
}
.ant-table-tbody > tr > td {
  color: #000000;
}
.ant-select {
  color: #000000;
}
.ant-select-selection-item > span {
  color: #000000;
}
.ant-select-arrow .anticon > svg {
  color: #000000;
}
.group-filter-btn,
.ant-dropdown-menu-title-content > a {
  color: #000000;
}
.group-filter-btn:hover {
  color: #000000;
}
.loader {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.1);
  z-index: 999999;
}
.loader .ant-spin {
  transform: scale(1.7);
}
.input-search.ant-input-affix-wrapper {
  border: 1px solid #89D4F0;
}
.input-search.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: #5dc1e6;
}
.input-search.ant-input-affix-wrapper-focused {
  border-color: #5dc1e6;
  box-shadow: 0 0 0 2px rgba(106, 210, 236, 0.3);
}
.input-search .ant-input::placeholder {
  color: #014050;
}
.dark-blue-btn {
  background: #172C44;
  border: none;
  color: white;
  border-radius: 6px;
}
.dark-blue-btn:hover {
  color: white;
  background: rgba(23, 44, 68, 0.75);
  border-color: rgba(23, 44, 68, 0.75);
}
.dark-blue-btn:focus {
  color: white;
  background: #172C44;
  border-color: #172C44;
}
.dark-magenta-btn {
  background: #462144;
  border-color: #462144;
  color: white;
  border-radius: 6px;
  display: flex;
  align-items: center;
  height: auto;
}
.dark-magenta-btn:hover {
  color: white;
  background: rgba(70, 33, 68, 0.75);
  border-color: rgba(70, 33, 68, 0.75);
}
.dark-magenta-btn:focus {
  color: white;
  background: #462144;
  border-color: #462144;
}
.cyan-btn {
  background: #3DB5C5;
  border-color: #3DB5C5;
  color: white;
  border-radius: 6px;
}
.cyan-btn:hover {
  color: white;
  background: rgba(61, 181, 197, 0.75);
  border-color: rgba(61, 181, 197, 0.75);
}
.cyan-btn:focus {
  color: white;
  background: #3DB5C5;
  border-color: #3DB5C5;
}
.soft-red-btn {
  background: #F36750;
  border-color: #F36750;
  color: white;
  border-radius: 6px;
}
.soft-red-btn:hover {
  color: white;
  background: rgba(243, 103, 80, 0.75);
  border-color: rgba(243, 103, 80, 0.75);
}
.soft-red-btn:focus {
  color: white;
  background: #F36750;
  border-color: #F36750;
}
.close-btn {
  background: lightgray;
  border-color: lightgray;
  color: black;
  border-radius: 6px;
}
.close-btn:hover {
  color: black;
  background: rgba(211, 211, 211, 0.75);
  border-color: rgba(211, 211, 211, 0.75);
}
.close-btn:focus {
  color: black;
  background: lightgray;
  border-color: lightgray;
}
.light-gray-btn {
  background: #F2F2F2;
  border-color: #F2F2F2;
  color: #172C44;
  border-radius: 6px;
}
.light-gray-btn:hover {
  color: #172C44;
  background: rgba(242, 242, 242, 0.75);
  border-color: rgba(242, 242, 242, 0.75);
}
.light-gray-btn:focus {
  color: #172C44;
  background: #F2F2F2;
  border-color: #F2F2F2;
}
.action-btn {
  color: gray;
  font-size: 18px !important;
}
.action-btn .anticon {
  display: flex;
  justify-content: flex-start;
}
.header-class {
  padding: 20px 20px;
}
.header-avatar {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  background-color: #999999 !important;
}
