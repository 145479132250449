@import '../../../views-dir.less';

.header-class {
  padding: 20px 20px;
}

.header-avatar {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  background-color: #999999 !important;
}