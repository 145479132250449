.content-layout {
    padding: 24px;
    padding-top: 0;
    min-height: calc(100vh - 84px);
    max-height: calc(100vh - 84px);
    overflow: auto;
}

.content-layout::-webkit-scrollbar {
    display: none;
}

.main-layout {
    height: 100vh;
    overflow: hidden;
}
@primary-color: #00AD4C;@border-radius-base: 5px;@border-color-base: #49CB5C;